import React from "react"
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom"

import PortfolioItem from "@components/PortfolioItem"
import NoResults from "@components/NoResults"

import { useAlgolia, useTranslation } from "@hooks"

import Box from "react-bulma-components/lib/components/box"
import Columns from "react-bulma-components/lib/components/columns"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const Hits = ({ hits }) => {
  return (
    <Columns breakpoint="mobile" gapless>
      {!hits.length ? (
        <NoResults />
      ) : (
        hits.map((hit, i) => <PortfolioItem key={i} photo={hit} />)
      )}
    </Columns>
  )
}

const CustomHits = connectHits(Hits)

const RecentLikes = ({ user }) => {
  const [t] = useTranslation()
  const searchClient = useAlgolia()
  return (
    <Box>
      <Content>
        <Heading renderAs={"h3"} subtitle size={5}>
          {t("components.RecentLikes.heading")}
        </Heading>
      </Content>
      <InstantSearch indexName={"photos"} searchClient={searchClient}>
        <Configure filters={`likes:${user.id}`} hitsPerPage={9} />
        <CustomHits />
      </InstantSearch>
    </Box>
  )
}

export default RecentLikes
