import React, { Fragment } from "react"
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom"
import Flag from "react-country-flag"

import { useAlgolia } from "@hooks"
import { BOOKING_STATUS_ACCEPTED, BOOKING_TRAVEL } from "@constants"

import Dates from "@components/Dates"
import NoResults from "@components/NoResults"

import Box from "react-bulma-components/lib/components/box"
import Columns from "react-bulma-components/lib/components/columns"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const Hits = ({ hits }) => {
  return (
    <Columns breakpoint="mobile" gapless>
      {!hits.length ? (
        <NoResults />
      ) : (
        hits.map((hit, i) => (
          <Fragment key={i}>
            <Flag
              countryCode={hit.countryCode}
              svg
              style={{ width: 50, marginRight: 15 }}
            />
            <Dates endDate={hit.endDate} startDate={hit.startDate} />
          </Fragment>
        ))
      )}
    </Columns>
  )
}

const CustomHits = connectHits(Hits)

const Travels = ({ user }) => {
  const searchClient = useAlgolia()
  return (
    <Box>
      <Content>
        <Heading subtitle size={5}>
          Travels
        </Heading>
      </Content>
      <InstantSearch indexName={"bookings"} searchClient={searchClient}>
        <Configure
          filters={`status:${BOOKING_STATUS_ACCEPTED} AND type:${BOOKING_TRAVEL} AND artist.id:${
            user?.id
          } AND endDateTimestamp >= ${Date.now()}`}
          hitsPerPage={9}
        />
        <CustomHits />
      </InstantSearch>
    </Box>
  )
}

export default Travels
