import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

const Dates = ({ endDate, format, startDate }) => (
  <p>
    {startDate ? moment(new Date(startDate)).format(format) : ""} -{" "}
    {endDate ? moment(new Date(endDate)).format(format) : ""}
  </p>
)

Dates.defaultProps = {
  endDate: "",
  format: "Do MMM YYYY",
  startDate: "",
}

Dates.propTypes = {
  endDate: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
}

export default Dates
