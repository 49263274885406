import React from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@lib/fontAwesome"
import { useTranslation } from "@hooks"

const Verified = ({ role, verified }) => {
  const [t] = useTranslation()
  return (
    <p className={"is-capitalized"}>
      <FontAwesomeIcon
        color={verified ? "yellow" : "rgba(23, 23, 23, 0.5)"}
        icon={["far", "check-circle"]}
        style={{ marginRight: 5 }}
      />
      {role}{" "}
      {verified
        ? t("components.Verified.verified")
        : t("components.Verified.pending")}
    </p>
  )
}

Verified.propTypes = {
  role: PropTypes.string.isRequired,
  verified: PropTypes.bool,
}

export default Verified
