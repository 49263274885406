import React from "react"
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom"

import { useAlgolia, useTranslation } from "@hooks"
import {
  BOOKING_STATUS_ACCEPTED,
  BOOKING_GUEST_REQUEST,
  BOOKING_GUEST_INVITE,
} from "@constants"
import User from "@components/User"
import NoResults from "@components/NoResults"

import Box from "react-bulma-components/lib/components/box"
import Columns from "react-bulma-components/lib/components/columns"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const Hits = ({ hits }) => {
  return (
    <Columns breakpoint="mobile" gapless>
      {!hits.length ? (
        <NoResults />
      ) : (
        hits.map((hit, i) => (
          <Columns.Column
            key={i}
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 8,
            }}
            desktop={{
              size: 8,
            }}
            widescreen={{
              size: 8,
            }}
            fullhd={{
              size: 8,
            }}
          >
            <User user={hit.artist} />
          </Columns.Column>
        ))
      )}
    </Columns>
  )
}

const CustomHits = connectHits(Hits)

const GuestArtists = ({ user }) => {
  const [t] = useTranslation()
  const searchClient = useAlgolia()
  return (
    <Box>
      <Content>
        <Heading renderAs={"h3"} subtitle size={5}>
          {t("components.GuestArtists.heading")}
        </Heading>
      </Content>
      <InstantSearch indexName={"bookings"} searchClient={searchClient}>
        <Configure
          filters={`status:${BOOKING_STATUS_ACCEPTED} AND type:${BOOKING_GUEST_REQUEST} OR type:${BOOKING_GUEST_INVITE} AND studio.id:${
            user?.id
          } AND endDateTimestamp >= ${Date.now()}`}
          hitsPerPage={9}
        />
        <CustomHits />
      </InstantSearch>
    </Box>
  )
}

export default GuestArtists
