import React from "react"
import classnames from "classnames"

import { useTranslation } from "@hooks"
import Verified from "@components/Verified"
import UserLocation from "@components/UserLocation"
import ResidentArtists from "@components/ResidentArtists"
import GuestArtists from "@components/GuestArtists"
import OpeningTimes from "@components/OpeningTimes"
import Styles from "@components/Styles"
// import UserProfileCounts from "@components/UserProfileCounts"
// import UserMap from "@components/UserMap"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Columns from "react-bulma-components/lib/components/columns"
import Box from "react-bulma-components/lib/components/box"
import Card from "react-bulma-components/lib/components/card"
import Media from "react-bulma-components/lib/components/media"
import Image from "react-bulma-components/lib/components/image"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const UserStudio = ({ user }) => {
  const [t] = useTranslation()

  if (!user) return null
  return (
    <Section>
      <Container>
        <Columns breakpoint="mobile">
          <Columns.Column
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 8,
            }}
            desktop={{
              size: 8,
            }}
            widescreen={{
              size: 8,
            }}
            fullhd={{
              size: 8,
            }}
          >
            <Box paddingless>
              <Card shadowless>
                <Card.Content>
                  <Media className={"is-vcentered"}>
                    <Media.Item renderAs="figure" position="left">
                      <Image
                        size={128}
                        alt={`Image of ${user.username}`}
                        src={user.photoUrl}
                        rounded
                        className={classnames("is-square", "user-image")}
                        loading={"lazy"}
                      />
                    </Media.Item>
                    <Media.Item>
                      <Heading size={4}>{user.name}</Heading>
                      <p>@{user.username}</p>
                      <Verified role={user.role} verified={user.verified} />
                      <UserLocation
                        city={user.city}
                        countryCode={user.countryCode}
                        geoloc={user._geoloc}
                      />
                    </Media.Item>
                  </Media>
                  <Content>
                    <Heading renderAs={"h3"} subtitle size={5}>
                      {t("components.UserStudio.about")}
                    </Heading>
                    <p className={"break-word"}>{user.description}</p>
                  </Content>
                </Card.Content>
              </Card>
            </Box>
            <Box>
              <Styles styles={user.styles} />
            </Box>
            <ResidentArtists user={user} />
            <GuestArtists user={user} />
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 4,
            }}
            desktop={{
              size: 4,
            }}
            widescreen={{
              size: 4,
            }}
            fullhd={{
              size: 4,
            }}
          >
            {/* <UserProfileCounts user={user} /> */}
            <OpeningTimes openingTimes={user.openingTimes} />
            {/* 
            <Box>
              <UserMap user={user} />
            </Box> 
            */}
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default UserStudio
