import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"

import Box from "react-bulma-components/lib/components/box"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const OpeningTimes = ({ openingTimes }) => {
  const daysKeys = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]

  return (
    <Box>
      <Content>
        <Heading subtitle size={5}>
          Opening Times
        </Heading>
        <ul>
          {daysKeys.map((key, i) => (
            <li key={i}>
              <span className={"is-capitalized"}>{key}: </span>
              <span>
                {get(openingTimes, key, {}).isOpen ? (
                  <>
                    {get(openingTimes, key, {})?.openTime}-
                    {get(openingTimes, key, {})?.closeTime}
                  </>
                ) : (
                  "Closed"
                )}
              </span>
            </li>
          ))}
        </ul>
      </Content>
    </Box>
  )
}

OpeningTimes.propTypes = {
  openingTimes: PropTypes.object,
}

export default OpeningTimes
