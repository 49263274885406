import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"

import { useTranslation } from "@hooks"
import { GlobalContext } from "@components/Layout"
import { selectCurrentUser } from "@selectors"
import * as ROUTES from "@constants/routes"

import Button from "react-bulma-components/lib/components/button"

const ButtonBook = ({ user }) => {
  const [t] = useTranslation()

  const currentUser = useSelector(selectCurrentUser)

  const book = e => {
    e.stopPropagation()
    e.preventDefault()
    navigate(ROUTES.BOOK)
  }

  const _getText = () => {
    // if (pendingOrAccepted) {
    //   return t('components.ButtonBook.view');
    // }

    // if ((user && !user.verified) || isFlash) {
    //   return t('components.ButtonBook.request');
    // }

    return t("components.ButtonBook.book")
  }

  return (
    <GlobalContext.Consumer>
      {({ registerModal }) => (
        <Button
          rounded
          fullwidth
          color="danger"
          renderAs="span"
          // className="has-background-black has-text-white-bis"
          onClick={!!currentUser ? book : registerModal}
        >
          {_getText()}
        </Button>
      )}
    </GlobalContext.Consumer>
  )
}

ButtonBook.defaultProps = {
  user: {},
}

ButtonBook.propTypes = {
  user: PropTypes.object.isRequired,
}

export default ButtonBook
