import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@lib/fontAwesome"

import Flag from "react-country-flag"

const UserLocation = ({ city, countryCode, geoloc, size, icon, color }) => {
  return (
    <p className={"is-valigned"}>
      {countryCode ? (
        <>
          <Flag
            countryCode={countryCode}
            svg
            style={{ width: 25, marginRight: 10 }}
          />
        </>
      ) : (
        <FontAwesomeIcon icon={icon} size={size} color={color} />
      )}{" "}
      {city}
    </p>
  )
}

UserLocation.defaultProps = {
  size: "2x",
  color: "#1c1c1e",
  icon: "map-marker-alt",
}

UserLocation.propTypes = {
  size: PropTypes.oneOf([
    "lg",
    "xs",
    "sm",
    "1x",
    "2x",
    "3x",
    "4x",
    "5x",
    "6x",
    "7x",
    "8x",
    "9x",
    "10x",
  ]).isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  city: PropTypes.string,
  countryCode: PropTypes.string,
  geoloc: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
}

export default UserLocation
